




import { Component, Vue, Emit } from 'vue-property-decorator';
import VueRouter, {Route} from 'vue-router';
import store from '../../store';
import Error from '../../components/contents/Error.vue';

Component.registerHooks([
  'beforeRouteEnter',
]);

@Component({
  components: {
    Error,
  },
})
export default class LogoutIndex extends Vue {
  private status: number = 200;
}
